
import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

import iconLayout from "../../assets/image/svg/time.svg";
import iconLayers from "../../assets/image/svg/cost.svg";
import iconResponsive from "../../assets/image/svg/recovery.svg";
import results from "../../assets/image/svg/results.svg";
import pain from "../../assets/image/svg/pain.svg";
import last from "../../assets/image/svg/last.svg";
import Himage from '../../assets/image/jpeg/home-bg2.jpg';

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;



@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: hsla(0, 0%, 100%, 0.8);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: hsla(0, 0%, 100%, 0.8);
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;










const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 4.2em;
      font-smooth: always;
     text-align:left;
     
      color: #fff;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 36px;
         text-align:center;
      }

`;


const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: url(${Himage}) no-repeat;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:0.03;


`;

const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;


width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 20px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;








  


const Secti = styled.section`


position: relative;

background-color: #000;


@media screen and (max-width: 767px)
{
  padding-top:2em;
 }

`;


const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
  color: white;
  margin-bottom: 10px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;


const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

const SectionStyled = styled(Section)`
  padding-top: 47px;
  padding-bottom: 23px;
  background: #000;
text-align:center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  @media ${device.md} {
    padding-top: 87px;
    padding-bottom: 63px;
  }
`;

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest}>
    <Box mb={[2, 2, 2, 2]} pb={1}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title color="white" variant="card" fontSize="16px" mb={1}>
        {title}
      </Title>
      <Text color="white" fontSize="14px" variant="small">{children}</Text>
    </Box>
  </Box>
);

const RecoveryHair = () => (
  <>
    {/* <!-- Feature section --> */}
    <Secti>
      <Imagi />
  
    <SectionStyled className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5">
      <Row className="mb-5">
                  <Col lg="12">
                  <Iwrap>


<Itext as="h2">PRP hair restoration summary</Itext>
</Iwrap>
      <SecondText>
      Quick Facts 
      </SecondText>
                  </Col>
                </Row>
        <Row className="justify-content-center">
       
        <Col lg="2" xs="4" className="mb-3 mb-lg-4 "
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="false"
            data-aos-delay="100" >
            <FeatureCard title="Treatment Time" iconImage={iconLayout}>
              30 minutes
            </FeatureCard>
          </Col>
          
          <Col lg="2" xs="4" className="mb-3 mb-lg-4"   data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false"
              data-aos-delay="200">
            <FeatureCard title="Results" iconImage={results}>
            Will appear within 2-3 months 

            </FeatureCard>
          </Col>
          
          <Col lg="2" xs="4" className="mb-3 mb-lg-4"   data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false"
              data-aos-delay="300">
            <FeatureCard title="Recovery" iconImage={iconResponsive}>
            None

            </FeatureCard>
          </Col>
          <Col lg="2" xs="4" className="mb-3 mb-lg-4" data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false"
              data-aos-delay="400">
            <FeatureCard title="Pain" iconImage={pain}>
            Minimal 

            </FeatureCard>
          </Col>
          <Col lg="2" xs="4" className="mb-3 mb-lg-4" data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false"
              data-aos-delay="500">
            <FeatureCard title="Cost" iconImage={iconLayers}>
            From £280 
            </FeatureCard>
          </Col>
          <Col lg="2" xs="4" className="mb-3 mb-lg-4" data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false"
              data-aos-delay="600">
            <FeatureCard title="Results Last" iconImage={last}>
            Long Lasting

            </FeatureCard>
          </Col>
       
       
        </Row>

       
   
      
      </Container>

      <Container>
      <Row className="mt-5">
                  <Col lg="12">
                 

      <SecondText>
     Package Deals Available on the day
      </SecondText>
      <br />
      <b>Consultation</b><br />
        <span className="strikey">£20</span>  £0<br /> <br />
<Text color="red">25% off for a limited time only!</Text>

      <Text color="#e5e5ea">
        <b>1 Session</b><br />
        <span className="strikey">£320</span>  £280<br /> <br />
        <b>3 Sessions</b><br />
        <span className="strikey">£960</span>   £720<br /> <br />
        <b>6 Sessions</b><br />
        <span className="strikey">£1920</span>   £1440<br /> <br />


      </Text>
                  </Col>
                </Row>
      </Container>
    </SectionStyled>
    </Secti>
  </>
);

export default RecoveryHair;




