import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/res/rescon";
import Prprecovery from "../sections/prp/prprecovery";
import Hero from "../sections/res/reshero";
import Faq from "../sections/res/resfaq";
import PRPgallery from "../sections/prp/prpgallery";
import Brand from "../sections/homepage/Brands.js";
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Testimonial from "../sections/advanced/advreviews";
import Pop from "../sections/landing/popular";
import Saveface from "../sections/landing/saveface";
import Clinic from '../sections/rhino/rhinofind.js';  
import Award from "../sections/index/award.js";
import TwoColumnLayout from '../shared/TwoColumnLayout.js';
import PopupForm from "../components/PopupForm/PopupForm"

const HairLossPRPPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
              <Head title="PRP Hair Restoration London" image="/images/dermamina-home.jpg"  description="Restore and strengthen hair with PRP hair treatment. Harness your body's natural healing for fuller, healthier hair. Discover the benefits now!" keywords="PRP hair treatment, Hair loss therapy, Platelet-rich plasma for hair, Hair regrowth treatment, PRP scalp treatment, Non-surgical hair restoration, Thinning hair solution, PRP injections for hair, Hair rejuvenation, Natural hair growth"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
'@type': 'ContactPoint',
telephone: '020 7099 6650',
contactType: 'Customer service',
},
address:{
'@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
{'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'PRP Hair Restoration',
item:'https://www.dermamina.com/prp-for-hair-loss',
},

]
}}
</JsonLd>

      <PageWrapper headerDark>
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
   
<Hero  />
<PRPgallery />
<Award />
<Content
  setIsModalOpen={setIsModalOpen}
/>




<Prprecovery />
<Testimonial />

<Faq
  setIsModalOpen={setIsModalOpen}
/>


 




<Pop />
 
<Clinic />  
    
<Brand />

<Saveface />



      </PageWrapper>
     
    </>
  );
};
export default HairLossPRPPage;
